.slider {
    width: 100%;
    position: relative;
    aspect-ratio: 2;
    border-radius: 6px;
    overflow: hidden;
}
.slider .slide {

    transition: 150ms;
}
.slider .slide img {
    display: block;
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 6px;
}

.about .slider,
.about .slider .slide img {
    border-radius: 0;
}