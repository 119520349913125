:root {
  --column-count: 2;
  --main-color: #fb611c;
  --inactive-color: #888;
}
.color-main {
  color: var(--main-color) !important;
}
a {
  color: var(--main-color);
  text-decoration: none;
}
.flex {
  display: flex;
}
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  border-radius: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: .75rem;
}
.btn-buy {
   background:  var(--main-color);
   border: var(--main-color) 1px solid;
   color: white;


}
.no-touch {
  touch-action: none;
}
.App {
  text-align: center;
  width: 100vw;
  overflow-x: hidden;
  padding-top: 56px;
  padding-bottom: 56px;
}

.logo-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  font-weight: 700;
  font-family: cursive;
}
.wrapper {
  margin-left: 1rem;
  margin-right: 1rem;
}
.section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
}
.section.wrapper {
  gap: 2rem 1rem;
}
.section .header {
  width: 100%;
  height: 3rem;
  background: rgba(128,128,128,0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  margin-top: 1rem;
}
.section .description {
  font-size: .875rem;
  width: 100%;
  text-align: left;
  line-height: 1.2;
}
.section .element {
  max-width: calc((100% - (1rem * (var(--column-count) - 1))) / var(--column-count));
  gap: 0.25rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.element .weight {
  white-space: nowrap;
  margin-top: 6px;
  color: #ccc;
}
.element .price {
  color: var(--main-color);
  font-weight: 700;
  font-size: 18px;
}
.section .element .description {
  flex: 1;
}
.element .buy-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.element-detail-layout {

  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(64,64,64,0.25);
  transform: translateX(100%);
}
.element-detail-layout.open {
  transform: translateX(0);
  z-index: 100;
}
.element-detail {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  background: white;
  border-radius: 1rem 1rem 0 0;
  z-index: 101;
  transform: translateY(100%);
  transition: 200ms;
  filter: drop-shadow(0px 0px 8px #0005);
  padding-bottom: 92px;
}
.open .element-detail {
  transform: translateY(0);
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

img.square {
  aspect-ratio: 1;
  display: block;
  max-width: 100%;
  border-radius: 6px;
}
.page-gap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.page-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  background: white;
  filter: drop-shadow(0px 4px 6px #0007);

}
.page-header-nav {
  flex: none;
  height: 56px;
  width: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.page-header .logo {
  width: auto;
  height: 32px;
}

.bottom-nav {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 54px;
  background: white;
  display: flex;
  flex-direction: row;
  z-index: 100;
  filter: drop-shadow(0px 0px 8px #333c);
}
.bottom-nav a {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: var(--inactive-color);
}

.bottom-nav a span {
  font-size: 11px;
  color: #888;
}
.bottom-nav a.active {
  color: var(--main-color);
}
.bottom-nav a.active span {
  font-weight: 600;
  color: var(--main-color);
}
.delivery-content {
  height: calc(100vh - 54px - 64px);
  display: flex;
  flex-direction: column;
}
.flex-1 {
  flex: 1
}
.row {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.delivery-item-name {
  text-align: left;
}
.delivery-items {
  border-top: #dedede 1px solid;
}
.delivery-items .wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  margin-bottom: 24px;

}
.content {
  text-align: left;
  font-size: 14px;
}
.preview-img-container {
  aspect-ratio: 1;
  background: #f1f5fa;
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.preview-img {
  max-width: 100%;
}
.preview-title {
  color: #333;
  font-weight: 700;
}


.product-name {
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  line-height: 1.75rem;
}
.product-description {
  text-align: left;
  margin-top: 10px;
}
.product-description .title {
  font-weight: bold;

}
.product-composition {
  font-size: 14px;
}
.product-nutritional-value {
  gap: 2px;
  font-size: 14px;
  margin-top: 6px;
  margin-bottom: 6px;
  padding: 6px 6px;
  border-radius: 6px;
  background: #fff8eb;
  display: flex;
  flex-direction: column;
}
.product-nutritional-item {
  display: flex;
  flex-direction: row;
  gap: 4px;
  color: #333;
}
.product-nutritional-item-title {
  flex: none;
}
.product-nutritional-item-dotted {
  flex: 1;
  border-bottom: #ccc 2px dotted;
}
.product-nutritional-item-value,
.product-nutritional-item-measure {
  font-weight: bold;
}
.product-storage-conditions {
  font-size: 14px;
}

.product-detail {
  text-align: left;
  padding-bottom: 56px;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.product-sku-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}
.product-sku-param {
  background: #eaeaea;
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 4px 10px;
  gap: 0.5rem;
}
.product-sku-param-value {
  font-size: 14px;
  font-weight: bold;
}
.product-sku {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.product-price {
  font-size: 1.125rem;
  color: var(--main-color);
  font-weight: 700;

}
.product-old-price {
  text-decoration: line-through;
  text-decoration-color: red;
  color: #ccc;
}
.dotted {
  flex: 1;
  border-bottom: #eaeaea 2px dotted;
}
.nav-label-count {
  background: var(--main-color);
  color: white !important;

  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 1px;
  font-size: 8px !important;
  line-height: 1;
}
.block-count-buy {
  display: flex;
  flex-direction: row;
}
.buy-count {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

}

.product-labels {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

}
.product-labels .label {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #aeaeae;
  margin-top: 0.5rem;
  height: 1rem;
  padding: 0.25rem 0.5rem;
  font-size: 11px;
  color: white;
  border-radius: 4px;
}
.product-labels .label.label-new {background-color: #00a407}
.product-labels .label.label-recommend {background-color: #f1a900;}

.product-preview {
  position: relative;
}
.product-preview img {
  border-radius: 6px;
}
.product-preview .product-labels {
  position: absolute;
  flex-direction: column;
  gap: 0.35rem;
  right: -2px;
  margin-top: 0.5rem;
}
.product-preview .product-labels .label {
  font-size: 9px;
  padding: 0.1rem 0.2rem;
  margin-top: 0rem;
  filter: drop-shadow(-2px 1px 2px #333a);
  border-radius: 4px 0 0 4px;
}

.basket {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-bottom: 58px;
}
.basket .basket-line {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  background: #ebebeb;
  padding: 0.5rem 0.5rem;
  border-radius: 6px;
}
.basket .basket-line .basket-line-preview {
  width: 3rem;
  height: 3rem;
  flex: none;
}
.basket .basket-line .basket-line-preview img {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 6px;
}
.basket .basket-line .basket-line-title {
  flex: 1;
  line-height: 1;
}
.basket .basket-line .basket-line-title a {
  color: #333;
}
.basket .basket-line .basket-line-count {
  flex: none;
  width: 6rem;
}
.basket .basket-line .basket-line-price {
  flex: none;
  width: 4rem;
  text-align: right;
  font-weight: bold;
}
.btn-order {
  height: 48px;
  position: fixed;
  left: 1rem;
  bottom: 60px;
  right: 1rem;
  background: var(--main-color);
  color: white;
  font-weight: bolder;
  border-radius: 6px;
  font-size: 1rem;
}
.btn-order.disabled {
  background: #969696;
}
.input-block {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 0.5rem;
}
.input-block label {
  color: #777;
}
.input-block input {
  background-color: #f2f3f5;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  border: none;
}
.search-result {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-bottom: 58px;
}
.search-result-item {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  background: #ebebeb;
  padding: 0.5rem 0.5rem;
  border-radius: 6px;

}
.search-result-item img {
  width: 4rem;
  aspect-ratio: 1;
  border-radius: 6px;
}
.search-result-item a {
  color: #000;
}
.contact-link {
  display: block;
  margin-bottom: 0.5rem;
  height: 48px;
  border: var(--main-color) 2px solid;
  color:  var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}
.home-text {
  text-align: left;
}
.btn.btn-normal {
  background: #f2f3f5;
  color: #111;
  margin-right: 0.5rem;
}

.section-slider {
  position: fixed;
  display: flex;
  flex-direction: row;

  gap: 1rem;
  top: 54px;
  background: white;
  z-index: 100;

  background: white;
  height: 48px;
  color: #333;

  width: 100%;
  overflow-x: auto;
  align-items: center;
}
.section-slider .section-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  background: #eaeaea;
  color: #000;
  font-weight: 700;

  padding: 0 16px;
  border-radius: 16px;
  height: 32px;
}
.section-slider .section-item:first-child {
  margin-left: 16px;
}
.section-slider .section-item-icon {
  height: 48px;
  width: 48px;

  object-fit: cover;
  display: block;
}

.section-slider .section-item-title {
  font-size: 11px;
  line-height: 1;

}
.productSlider {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 15px;
}
.productSlider .productSliderItem {
  width: 40vw;
  color: #333;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 10px;
}
.productSlider .productSliderItem img {
  width: 40vw;
  height: 40vw;
  object-fit: contain;
  border-radius: 6px;
}